import React from 'react';
import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import JavascriptSvg from 'svgs/blog/javascript.svg';
import EmptyWalletIcon from 'svgs/icons/ic-empty-wallet-tick.svg';
import MaximizeIcon from 'svgs/icons/ic-maximize.svg';
import MonitorMobileIcon from 'svgs/icons/ic-monitor-mobile.svg';
import PeopleIcon from 'svgs/icons/ic-people-alt.svg';
import TimerStartIcon from 'svgs/icons/ic-timer-start.svg';
import DiscordLogo from 'svgs/technologies/react-js/companies/logo-discord.svg';
import DiscoveryLogo from 'svgs/technologies/react-js/companies/logo-discovery.svg';
import KhanAcademyLogo from 'svgs/technologies/react-js/companies/logo-khan-academy.svg';
import WhatsappLogo from 'svgs/technologies/react-js/companies/logo-whatsapp.svg';
import CertifiedIcon from 'svgs/technologies/work-with-mr/award.svg';
import MarketIcon from 'svgs/technologies/work-with-mr/calendar-tick.svg';
import MaintainersIcon from 'svgs/technologies/work-with-mr/code.svg';
import ClientsIcon from 'svgs/technologies/work-with-mr/people.svg';
export const PERKS_LIST = [
    {
        title: 'react-js.workWithMobileReality.onTheMarket',
        icon: <MarketIcon />,
    },
    {
        title: 'react-js.workWithMobileReality.certifiedDevelopers',
        icon: <CertifiedIcon />,
    },
    {
        title: 'react-js.workWithMobileReality.ownersAndMaintainers',
        icon: <MaintainersIcon />,
    },
    {
        title: 'react-js.workWithMobileReality.experiencedInWorking',
        icon: <ClientsIcon />,
    },
];

export const SECTIONS_TRANSLATION = [
    'react-js.topics.codeConsulting',
    'react-js.topics.famousApps',
    'react-js.topics.objectiveAdvantages',
    'react-js.topics.hireDevelopers',
    'react-js.topics.businessBenefits',
    'react-js.topics.whyWorkWithMobileReality',
];

export const SECTIONS_ID = [
    'react-js-code-consulting',
    'react-js-famous-apps',
    'react-js-objective-advantages',
    'react-js-hire-developers',
    'react-js-business-benefits',
    'react-js-why-work-with-mobile-reality',
];

export const COMPANY_LOGOS = [
    {
        logo: <WhatsappLogo />,
        title: 'react-js.famousApps.whatsapp.title',
        description: 'react-js.famousApps.whatsapp.description',
    },
    {
        logo: <DiscordLogo />,
        title: 'react-js.famousApps.discord.title',
        description: 'react-js.famousApps.discord.description',
    },
    {
        logo: <KhanAcademyLogo />,
        title: 'react-js.famousApps.khanAcademy.title',
        description: 'react-js.famousApps.khanAcademy.description',
    },
    {
        logo: <DiscoveryLogo />,
        title: 'react-js.famousApps.discovery.title',
        description: 'react-js.famousApps.discovery.description',
    },
];

export const ADVANTAGES = [
    {
        index: 1,
        section: 'performance',
        paragraphsCount: 1,
    },
    {
        index: 2,
        section: 'acceleration',
        paragraphsCount: 1,
    },
    {
        index: 3,
        section: 'stability',
        paragraphsCount: 1,
    },
];

const iconProps = {
    width: 42,
    height: 42,
};

export const BUSINESS_BENEFITS = [
    {
        title: 'react-js.businessBenefits.benefits.engagement.title',
        description:
            'react-js.businessBenefits.benefits.engagement.description',
        icon: <PeopleIcon {...iconProps} />,
    },
    {
        title: 'react-js.businessBenefits.benefits.time.title',
        description: 'react-js.businessBenefits.benefits.time.description',
        icon: <TimerStartIcon {...iconProps} />,
    },
    {
        title: 'react-js.businessBenefits.benefits.performance.title',
        description:
            'react-js.businessBenefits.benefits.performance.description',
        icon: <MaximizeIcon {...iconProps} />,
    },
    {
        title: 'react-js.businessBenefits.benefits.efficiency.title',
        description:
            'react-js.businessBenefits.benefits.efficiency.description',
        icon: <EmptyWalletIcon {...iconProps} />,
    },
    {
        title: 'react-js.businessBenefits.benefits.compatibility.title',
        description:
            'react-js.businessBenefits.benefits.compatibility.description',
        icon: <MonitorMobileIcon {...iconProps} />,
    },
];

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    showAllArticles: true,
    tags: [BLOG_POST_TAGS.REACT_JS],
    button: {
        to: `${PATHS.BLOG}/${PATHS.JAVASCRIPT}`,
        label: 'react-js.footer.button',
    },
    categoryBanner: {
        title: 'react-js.footer.title',
        description: 'react-js.footer.description',
        svg: JavascriptSvg,
    },
};
